.cta-block {
  margin-top: 64px;
  margin-bottom: 64px;

  font-size: 6.4rem;
  background: #252525;
  display: flex;
  letter-spacing: 0.05em;
  width: 100%;
  justify-content: center;
  margin-left: auto;
  padding: 64px 128px;
  color: white;
  transition-duration: 0.3s;

  @media all and (max-width: 1600px) {
    font-size: 4.8rem;
    padding: 48px 96px;
  }

  @media all and (max-width: 1200px) {
    font-size: 4.2rem;
    padding: 32px 64px;
    margin-top: 32px;
  }

  @media all and (max-width: 700px) {
    font-size: 2.8rem;
    padding: 24px 32px;
    margin-top: 0px;
  }

  @media all and (max-width: 400px) {
    font-size: 2rem;
    padding: 18px;
    margin-top: 0px;
  }

  a {
    color: white;
    transition-duration: 0.3s;
  }

  .content {
    display: block;
    margin-left: 48px;
    margin-right: 48px;

    @media all and (max-width: 1400px) {
      margin-left: 0px;
      margin-right: 0px;
      text-align: center;
    }
  }

  .offset {
    width: 38px;
    margin-left: auto;
    margin-right: 48px;

    @media all and (max-width: 1400px) {
      display: none;
    }
  }

  .arrowRight {
    width: 38px;
    margin-left: 48px;
    margin-right: auto;
    margin-bottom: -4px;
    filter: brightness(2);
    transition-duration: 0.3s;

    @media all and (max-width: 700px) {
      display: none;
    }
  }

  &:hover {
    a {
      color: #F2C98B;
    }

    .arrowRight {
      filter: brightness(1);
      transform: translateX(16px);
    }
  }

  &:active {
    .arrowRight {
      filter: brightness(1);
      transform: translateX(32px);
    }
  }
}