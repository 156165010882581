.heroBlock {
  height: calc(100vh - 192px);
  display: flex;
  align-items: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 128px;
  position: relative;
  min-height: 300px;

  @media all and (max-width: 700px) {
    height: calc(100vh - 140px);
    margin-bottom: calc(64px + 32px);
    background-position: center center;
  }

  .header-arrowDown {
    width: 100%;
    position: absolute;
    transform: translateY(100%);
    bottom: -24px;
    filter: invert(1);
    text-align: center;
    img {
      height: 24px;
      @media all and (max-width: 700px) {
        filter: invert(1);
      }
    }
  }

  .header-title {
    background: #252525;
    width: auto;
    padding: 32px 128px;
    display: flex;
    color: white;
    font-size: 6.4rem;
    letter-spacing: 0.05em;
    width: calc(50% - 16px);
    justify-content: center;
    margin-left: auto;
    transform: translateY(50%);
    position: relative;

    @media all and (max-width: 1600px) {
      font-size: 4.8rem;
      padding: 32px 64px;
    }

    @media all and (max-width: 1000px) {
      font-size: 4.2rem;
      padding: 24px 32px;
    }

    @media all and (max-width: 700px) {
      font-size: 5rem;

      padding: 24px 32px;
      width: 100%;
      height: 100%;
      transform: none;
      background: rgba(49, 49, 49, 0.6);
      align-items: center;
    }

    @media all and (max-width: 400px) {
      padding: 18px 24px;
    }

    .title {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .offset {
      width: 16px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      width: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -4px;

      @media all and (max-width: 1000px) {
        width: 14px;
      }

      @media all and (max-width: 700px) {
        width: 12px;
      }
    }
  }
}

.heroMapBlock {
  background: none !important;
  position: relative;

  .map-section {
    height: calc(100vh - 192px);
    position: absolute;
    top: 0px;
    overflow: hidden;

    .map {
      height: calc(100vh - 100px);
    }
  }
}
