.collapsible-block {
  // margin-top: 16px;
  // margin-bottom: 16px;

  background: #252525;


  width: 50%;
  position: absolute;
  bottom: -72px;
  right: 0px;
  // position: a;
  // justify-content: center;
  // margin-left: auto;
  // transform: translateY(50%);
  // position: relative;


  .collapsible-block__title {
    text-align: center;
    padding: 32px 128px;
    user-select: none;
    cursor: pointer;
    position: relative;
    display: flex;

    @media all and (max-width: 1600px) {
      padding: 32px;
      font-size: 3.2rem;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
      font-size: 2.8rem;
    }

    @media all and (max-width: 800px) {
      padding: 16px 64px 16px 16px;
      text-align: left;
      font-size: 2.4rem;
    }

    @media all and (max-width: 500px) {
      font-size: 2rem;
    }

    .title {
      font-weight: 500;
      color: white;
      font-size: 6.4rem;
      letter-spacing: 0.05em;
      width: auto;
      // padding: 32px 128px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .offset {
      width: 16px;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      width: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -4px;
      transform: scaleY(-1);
      transition-duration: 0.3s;
    }


    img.isOpen {
      transform: scaleY(1);
    }

    &.isSplited {
      padding-right: calc(60% + 32px);

      @media all and (max-width: 1600px) {
        padding-right: 50%;
      }

      @media all and (max-width: 900px) {
        padding-right: 16px;
      }
    }
  }

  .collapsible-block__content {
    padding: 32px;
    padding-top: 0px;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.6;
    margin-left: calc(16px + 32px); // icon + title margin
    color: white;
    @media all and (max-width: 1600px) {
      padding: 32px;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
      line-height: 1.2;
    }

    @media all and (max-width: 900px) {
      margin-left: 0px;
    }

    @media all and (max-width: 800px) {
      padding: 16px;
      line-height: 0.8;
      padding-top: 0px;
    }


    &.isSplited {
      margin-left: calc(40% + 32px);
      width: calc(60% - 32px);

      @media all and (max-width: 1600px) {
        margin-left: calc(50% + 16px);
        width: calc(50% - 16px);
      }

      @media all and (max-width: 900px) {
        width: 100%;
        margin-left: 0px;
      }
    }


  }



  .ReactCollapse--collapse {
    transition: height 0.3s;
  }
}

.collapsible-blocks-wrapper {}