.footer {
  .container {
    padding-top: 0px;
    padding-bottom: 16px;
    flex-direction: row;
    flex-direction: column;

    .left {
      font-size: 1.6rem;
      margin-left: auto;
      margin-top: 3px;
      margin-bottom: 8px;
    }

    .center {
      // margin-left: auto;
      margin-left: auto;
      font-size: 1.6rem;

      margin-top: 2px;
      padding-right: 2px;

      a {
        height: 32px;
      }

      img {
        height: 26px;

        @media all and (max-width: 1200px) {
          height: 22px;
        }
      }
    }
  }
}