.process-section {
  padding-top: 64px;
  padding-bottom: 64px;
  background: #252525;

  .process-step {
    margin-top: 32px;
    margin-bottom: 32px;

    @media all and (max-width: 1200px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    @media all and (max-width: 1200px) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .process-block {
    color: white;
    background: #252525;
    width: 100%;
    padding: 96px;
    text-align: center;

    @media all and (max-width: 1600px) {
      padding: 64px;
    }

    @media all and (max-width: 1400px) {
      padding: 48px;
    }

    @media all and (max-width: 700px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }

    .title {
      font-size: 4.8rem;
      letter-spacing: 0.05em;
      padding-bottom: 64px;

      @media all and (max-width: 1600px) {
        padding-bottom: 32px;
        font-size: 4rem;
      }

      @media all and (max-width: 1400px) {
        font-size: 3.4rem;
      }

      @media all and (max-width: 700px) {
        font-size: 4.4rem;
      }
    }

    .grid {
      padding-top: 32px;
      padding-bottom: 0px;

      @media all and (max-width: 1600px) {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      @media all and (max-width: 1400px) {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      .col-2 {
        @media all and (max-width: 1920px) {
          padding-top: 32px;
          padding-bottom: 32px;
        }

        @media all and (max-width: 1200px) {
          padding-top: 18px;
          padding-bottom: 18px;
        }

        @media all and (max-width: 700px) {
          width: 100%;
          // display: flex;
          // text-align: left;
          // align-items: center;
          margin-top: 16px;
        }

        @media all and (max-width: 540px) {
          width: 100%;
        }
      }

      .numBlock {
        height: 100px;
        width: 100px;
        font-size: 6.4rem;
        border: 1px solid white;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;

        @media all and (max-width: 1600px) {
          font-size: 4.6rem;
          height: 80px;
          width: 80px;
        }

        @media all and (max-width: 1400px) {
          font-size: 4rem;
          height: 70px;
          width: 70px;
        }

        @media all and (max-width: 700px) {
          height: 60px;
          width: 60px;
          font-size: 3rem;
          margin-bottom: 12px;
          // margin-left: 0px;
          // margin-right: 24px;
        }
      }

      .description {
        font-size: 2.4rem;
        padding-top: 24px;
        padding-bottom: 24px;

        @media all and (max-width: 1600px) {
          font-size: 2rem;
          padding-top: 24px;
        }

        @media all and (max-width: 700px) {
          padding-top: 4px;
        }
      }
    }
  }
}
