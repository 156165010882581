.fullImg-block {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  @media all and (max-width: 700px) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  img {
    width: 100%;
    height: auto;
  }
}