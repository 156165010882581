.kontakt-page {
  .iconsWrapper {
    display: flex;
    padding-top: 8px;
    padding-bottom: 16px;

    a {
      margin-right: 16px;
      padding: 4px;

      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
        filter: brightness(2);
        transition-duration: 0.3s;

        &:hover {
          filter: brightness(1);
        }
      }
    }
  }

  .collapsible-block .collapsible-block__title {
    .title {
      @media all and (max-width: 1600px) {
        font-size: 5rem;
      }

      @media all and (max-width: 1400px) {
        font-size: 4.6rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 4rem;
      }
    }

    .imgIcon {
      @media all and (max-width: 1400px) {
        width: 14px;
      }

      @media all and (max-width: 1200px) {
        width: 12px;
      }
    }
  }

  .darkBlock {
    margin-top: 72px;
    margin-bottom: 32px;
    background: #252525;
    padding: 32px;
    padding-bottom: 0px;

    @media all and (max-width: 500px) {
      margin-top: 68px;
    }

    .topLinkWrapper {
      display: none;
    }
  }
}
