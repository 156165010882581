html{
  font-size: 10px;
  font-family: 'Playfair Display', serif;
  color: white;
  overflow: auto;
  background: #252525;
  /* font-feature-settings: 'pnum' on, 'lnum' on; */
  font-feature-settings: "lnum" 1 !important;

}

@media all and (max-width: 1200px) {
  html{
    font-size: 9px;
  }
}

@media all and (max-width: 600px) {
  html{
    font-size: 8px;
  }
}

body {
  position: relative;
  overflow: hidden;
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}