.half-hero-block {
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;

  .title {
    font-size: 4.8rem;
    letter-spacing: 0.05em;
    padding-right: 32px;

    @media all and (max-width: 1600px) {
      font-size: 3.6rem;
    }

    @media all and (max-width: 1400px) {
      font-size: 3.4rem;
    }

    @media all and (max-width: 1200px) {
      font-size: 3.2rem;
      letter-spacing: 0.025em;
    }
  }

  .topCorner,
  .botCorner {
    width: calc(100% - 128px);
    height: 100px;
  }

  .topCorner {
    margin-bottom: 128px;
    border-top: 10px solid;

    @media all and (max-width: 1600px) {
      margin-bottom: 96px;
      border-top: 8px solid;
    }
  }

  .botCorner {
    margin-top: 128px;
    border-bottom: 10px solid;

    @media all and (max-width: 1600px) {
      margin-top: 96px;
      border-bottom: 8px solid;
    }
  }

  &.half-hero-block--left {
    padding-left: 128px;
    align-items: flex-start;
    text-align: left;

    @media all and (max-width: 1600px) {
      padding-left: 0px;
    }

    @media all and (max-width: 700px) {
      display: none;
    }

    .topCorner,
    .botCorner {
      margin-left: 128px;
      border-left: 10px solid;

      @media all and (max-width: 1600px) {
        border-left: 8px solid;
      }
    }
  }

  &.half-hero-block--right {
    padding-right: 128px;
    align-items: flex-end;
    text-align: right;

    @media all and (max-width: 1600px) {
      padding-right: 32px;
    }

    @media all and (max-width: 700px) {
      display: none;
    }

    .topCorner,
    .botCorner {
      margin-right: 128px;
      border-right: 10px solid;

      @media all and (max-width: 1600px) {
        border-right: 8px solid;
      }
    }
  }

  &.half-hero-block--white {
    background: #252525;

    .title {
      color: #252525;
    }

    .topCorner,
    .botCorner {
      border-color: #252525;
    }
  }

  &.half-hero-block--dark {
    background: #252525;

    .title {
      color: white;
    }

    .topCorner,
    .botCorner {
      border-color: white;
    }
  }
}
