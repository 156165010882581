.prefooter {
  // padding-top: px;
  // padding-bottom: 64px;

  @media all and (max-width: 900px) {
    margin-top: 16px;
    padding-top: 16px;
    padding-bottom: 0px;
  }

  .container {
    .rowInfo {
      display: flex;
      // border-top: 1px solid #252525;
      background: #252525;
      // background: white;
      justify-content: center;
      text-align: center;
      width: 100%;
      padding: 64px 128px;

      .topLinkWrapper {
        margin-left: auto;
        margin-right: auto;
        font-size: 2rem;
        color: #252525;
        letter-spacing: 0.05em;
        padding: 8px;
      }

      @media all and (max-width: 900px) {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
}