.page-wrapper {
  width: 100%;
  padding-top: 100px;

  @media all and (max-width: 1600px) {
    padding-top: 96px;
  }

  @media all and (max-width: 1200px) {
    padding-top: 64px;
  }

  .pageWrapper__section {
    margin-top: 32px;
    padding-top: 64px;
    padding-bottom: 64px;

    @media all and (max-width: 1200px) {
      padding-bottom: 32px;
    }
  }
}