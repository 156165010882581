.gridNav {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media all and (max-width: 900px) {
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .gridNav_item {
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    font-size: 1.6rem;
    letter-spacing: 0.03em;
    cursor: pointer;
    white-space: nowrap;

    @media all and (max-width: 1200px) {
      font-size: 1.4rem;
      letter-spacing: 0;
    }
    @media all and (max-width: 900px) {
      width: 25%;
      // text-align: center;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    @media all and (max-width: 600px) {
      width: 50%;
      // text-align: center;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    &:hover {
      color: #f2c98b;
      @media all and (max-width: 900px) {
        color: white;
      }
    }
  }
}

.gallery-item-block {
  background-position: center;
  background-size: cover;
  border: 5px solid rgba(49, 49, 49, 1);
  overflow: hidden;
  width: 33.3333%;
  height: 500px;
  transform: scale(1);
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media all and (max-width: 1600px) {
    height: 360px;
  }

  @media all and (max-width: 1000px) {
    width: 50%;
  }

  @media all and (max-width: 700px) {
    width: 100%;
    height: calc(100vw - 24px);
    max-height: 500px;
  }

  .bgBlur {
    position: absolute;
    top: 0px;
    left: 0px;
    // background: rgba(49, 49, 49, 0.9);
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition-duration: 0.3s;
    transform: scale(1.35);

    // @-moz-document url-prefix() {
    //   background: rgba(49, 49, 49, 49.75);
    // }

    @media all and (max-width: 700px) {
      background-position: center;
      background-size: cover;
      opacity: 1;
      position: relative;
      transform: scale(1);
    }
  }

  .title {
    font-size: 3.2rem;
    letter-spacing: 0.03em;
    transform: translateY(16px) scale(0.75);
    padding-bottom: 32px;

    @media all and (max-width: 1600px) {
      font-size: 2.4rem;
    }

    @media all and (max-width: 700px) {
      padding-bottom: 0px !important;
      font-size: 1.6rem;
      transform: none;
      width: 100%;
    }
  }

  .description {
    font-size: 1.6rem;
    transform: translateY(-16px) scale(0.95);

    @media all and (max-width: 1600px) {
      font-size: 1.4rem;
      line-height: 1.5;
    }

    @media all and (max-width: 700px) {
      transform: none;
      font-size: 1.3rem;
      width: 100%;
      padding-top: 8px !important;
      font-weight: 400 !important;
    }
  }

  .title,
  .description {
    text-align: center;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: bold;
    transition-duration: 0.6s;
    max-width: 420px;
    z-index: 2;
    opacity: 0;
    // filter: blur(3px);
    user-select: none;
    color: white;

    @media all and (max-width: 700px) {
      opacity: 1;
      background: #252525;
      // filter: blur(0px);
      max-width: 100%;
      padding: 18px;
      text-align: left;
      display: none;
    }
  }
  &.isClickable {
    cursor: pointer;
    &:hover {
      // .bgBlur {
      //   background: rgba(49, 49, 49, 0.9);
      // }

      border: 5px solid #f2c98b;
    }
  }

  &:hover {
    .title,
    .description {
      // opacity: 1;
      // transform: translateY(0px) scale(1);
      // filter: blur(0px);
    }

    .bgBlur {
      // backdrop-filter: blur(8px);
      // opacity: 1;
    }
  }

  &.isHidden {
    display: none;
  }
}

.gridGallery {
  display: flex;
  flex-wrap: wrap;
  min-height: 1000px;
  transition-duration: 0.5s;
  opacity: 1;
  @media all and (max-width: 600px) {
    flex-direction: column;
    min-height: auto;
  }
  .topLinkWrapper {
    @media all and (max-width: 1000px) {
      width: 50%;
    }

    @media all and (max-width: 700px) {
      width: 100%;
    }
  }
  &.hideAll {
    transition-duration: 0.5s;
    opacity: 0;

    .gridGallery_item {
      transition-duration: 0.5s;
      transform: scale(0);
    }
  }
}
