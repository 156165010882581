.halfImgHalfTextRow {
  margin-top: 64px;
  margin-bottom: 64px;

  @media all and (max-width: 700px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .contentWrapper {
    padding: 128px;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: calc((2 * 128px) + 512px);
    margin-left: auto;
    margin-right: auto;

    @media all and (max-width: 1400px) {
      padding: 72px;
    }

    @media all and (max-width: 1200px) {
      padding: 64px;
    }

    @media all and (max-width: 700px) {
      padding: 32px;
    }

    @media all and (max-width: 400px) {
      padding: 0px;
    }
  }

  &.reverseMode {
    flex-direction: row-reverse;
  }
}