.about-section {
  padding-top: 64px;
  padding-bottom: 64px;

  .container {
    height: calc(100vh - 180px);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @media all and (max-width: 1200px) {
      height: calc(85vh - 180px);
    }

    @media all and (max-width: 700px) {
      height: auto;
    }
  }

  .whitePart,
  .darkPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
  }

  .darkPart {
    @media all and (max-width: 1200px) {
      width: 60%;
    }

    @media all and (max-width: 1000px) {
      width: 65%;
    }

    @media all and (max-width: 700px) {
      width: 100%;
      padding-top: 64px;
      padding-bottom: 64px;
      text-align: center;
    }

    @media all and (max-width: 400px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }


  .darkPart {
    background: #252525;

    @media all and (max-width: 700px) {
      padding-right: 0px;
      padding-left: 0px;
      padding-top: 64px;
      padding-bottom: 64px;
      font-size: 3rem;

    }

    @media all and (max-width: 400px) {
      padding-right: 0px;
    }

    .title {
      margin-left: 128px;
      font-size: 4.8rem;
      color: white;
      line-height: 0.9;
      letter-spacing: 0.05em;
      font-weight: 400;
      padding-bottom: 16px;
      text-align: left;

      @media all and (max-width: 1600px) {
        font-size: 4rem;
        margin-left: 0px;
        padding-left: 64px;
      }

      @media all and (max-width: 1200px) {
        font-size: 3.6rem;
        margin-left: 0px;
        padding-left: 32px;
      }

      @media all and (max-width: 700px) {
        padding-left: 16px;
        text-align: center;
        margin-left: 0px;
        padding-left: 0px;
        font-size: 4.4rem;

      }
    }

    p {
      margin-left: 128px;
      max-width: 452px;
      padding-top: 16px;
      padding-bottom: 16px;
      color: white;

      @media all and (max-width: 1600px) {
        margin-left: 0px;
        padding-left: 64px;
        padding-right: 32px;
        max-width: 100%;
      }

      @media all and (max-width: 1200px) {
        padding-left: 32px;
      }

      @media all and (max-width: 700px) {
        padding-left: 32px;
      }
    }

    .topLinkWrapper {
      margin-left: 128px;

      @media all and (max-width: 1600px) {
        margin-left: 0px;
        padding-left: 64px;
      }

      @media all and (max-width: 1200px) {
        padding-left: 32px;
      }

      @media all and (max-width: 700px) {
        padding-left: 16px;
        padding-left: 0px;
      }
    }
  }
}