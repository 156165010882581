.grid {

  .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &.align-center {
      align-items: center;
    }

    .col {
      &.col-offset-1 {
        margin-left: calc((100% / 12) * 1);
      }

      &.col-offset-2 {
        margin-left: calc((100% / 12) * 2);
      }

      &.col-offset-3 {
        margin-left: calc((100% / 12) * 3);
      }

      &.col-offset-4 {
        margin-left: calc((100% / 12) * 4);
      }

      &.col-offset-7 {
        margin-left: calc((100% / 12) * 7);
      }

      &.col-offset-8 {
        margin-left: calc((100% / 12) * 8);
      }

      &.col-offset-9 {
        margin-left: calc((100% / 12) * 9);
      }

      &.col-offset-10 {
        margin-left: calc((100% / 12) * 10);
      }

      &.col-1 {
        width: calc((100% / 12) * 1);
      }

      &.col-2 {
        width: calc((100% / 12) * 2);
      }

      &.col-3 {
        width: calc((100% / 12) * 3);
      }

      &.col-4 {
        width: calc((100% / 12) * 4);
      }

      &.col-5 {
        width: calc((100% / 12) * 5);
      }

      &.col-6 {
        width: calc((100% / 12) * 6);
      }

      &.col-8 {
        width: calc((100% / 12) * 8);
      }

      &.col-9 {
        width: calc((100% / 12) * 9);
      }

      &.col-10 {
        width: calc((100% / 12) * 10);
      }

      &.col-12 {
        width: calc((100% / 12) * 12);
      }

      @media all and (max-width: 1652px) {
        &.col-md-offset-0 {
          margin-left: calc((100% / 12) * 0);
        }

        &.col-md-offset-1 {
          margin-left: calc((100% / 12) * 1);
        }

        &.col-md-offset-2 {
          margin-left: calc((100% / 12) * 2);
        }

        &.col-md-offset-3 {
          margin-left: calc((100% / 12) * 3);
        }

        &.col-md-offset-4 {
          margin-left: calc((100% / 12) * 4);
        }

        &.col-md-offset-8 {
          margin-left: calc((100% / 12) * 8);
        }

        &.col-md-offset-9 {
          margin-left: calc((100% / 12) * 9);
        }

        &.col-md-offset-10 {
          margin-left: calc((100% / 12) * 10);
        }

        &.col-md-1 {
          width: calc((100% / 12) * 1);
        }

        &.col-md-2 {
          width: calc((100% / 12) * 2);
        }

        &.col-md-3 {
          width: calc((100% / 12) * 3);
        }

        &.col-md-4 {
          width: calc((100% / 12) * 4);
        }

        &.col-md-5 {
          width: calc((100% / 12) * 5);
        }

        &.col-md-6 {
          width: calc((100% / 12) * 6);
        }

        &.col-md-8 {
          width: calc((100% / 12) * 8);
        }

        &.col-md-9 {
          width: calc((100% / 12) * 9);
        }

        &.col-md-10 {
          width: calc((100% / 12) * 10);
        }

        &.col-md-12 {
          width: calc((100% / 12) * 12);
        }
      }

      @media all and (max-width: 1200px) {

        &.col-sm-offset-0 {
          margin-left: calc((100% / 12) * 0);
        }

        &.col-sm-0 {
          display: none;
          width: calc((100% / 12) * 0);
        }

        &.col-sm-1 {
          width: calc((100% / 12) * 1);
        }

        &.col-sm-2 {
          width: calc((100% / 12) * 2);
        }

        &.col-sm-3 {
          width: calc((100% / 12) * 3);
        }

        &.col-sm-4 {
          width: calc((100% / 12) * 4);
        }

        &.col-sm-5 {
          width: calc((100% / 12) * 5);
        }

        &.col-sm-6 {
          width: calc((100% / 12) * 6);
        }

        &.col-sm-8 {
          width: calc((100% / 12) * 8);
        }

        &.col-sm-10 {
          width: calc((100% / 12) * 10);
        }

        &.col-sm-12 {
          width: calc((100% / 12) * 12);
        }
      }
    }

    &.row-gap-64 {
      margin-left: -64px;
      width: calc(100% + (2 * 64px));

      .col {
        padding: 0px 64px;
      }
    }

    &.row-gap-32 {
      margin-left: -32px;
      width: calc(100% + (2 * 32px));

      .col {
        padding: 4px 32px;
      }
    }

    &.row-gap-16 {
      margin-left: -16px;
      width: calc(100% + (2 * 16px));

      .col {
        padding: 4px 16px;
      }
    }

    &.row-gap-0 {
      margin-left: 0px;
      width: 100%;

      .col {
        padding: 0px 0px;
      }
    }

    @media all and (max-width: 1200px) {
      &.row-gap-64 {
        margin-left: -32px;
        width: calc(100% + (2 * 32px));

        .col {
          padding: 4px 32px;
        }
      }

      &.row-gap-32 {
        margin-left: -16px;
        width: calc(100% + (2 * 16px));

        .col {
          padding: 4px 16px;
        }
      }

      &.row-gap-16 {
        margin-left: 0;
        width: calc(100%);

        .col {
          padding: 0px 0px;
        }
      }

      &.row-gap-0 {
        margin-left: 0;
        width: calc(100%);
      }
    }

    @media all and (max-width: 900px) {
      .col {

        &.col-xs-offset-0 {
          margin-left: calc((100% / 12) * 0);
        }

        &.col-xs-offset-1 {
          margin-left: calc((100% / 12) * 1);
        }

        &.col-xs-offset-2 {
          margin-left: calc((100% / 12) * 2);
        }

        &.col-xs-0 {
          display: none;
          width: calc((100% / 12) * 0);
        }

        &.col-xs-1 {
          width: calc((100% / 12) * 1);
        }

        &.col-xs-2 {
          width: calc((100% / 12) * 2);
        }

        &.col-xs-3 {
          width: calc((100% / 12) * 3);
        }

        &.col-xs-4 {
          width: calc((100% / 12) * 4);
        }

        &.col-xs-5 {
          width: calc((100% / 12) * 5);
        }

        &.col-xs-6 {
          width: calc((100% / 12) * 6);
        }

        &.col-xs-8 {
          width: calc((100% / 12) * 8);
        }

        &.col-xs-10 {
          width: calc((100% / 12) * 10);
        }

        &.col-xs-12 {
          width: calc((100% / 12) * 12);
        }
      }
    }

    @media all and (max-width: 700px) {
      &.row-gap-64 {
        margin-left: -16px;
        width: calc(100% + (2 * 16px));

        .col {
          padding: 4px 16px;
        }
      }

      &.row-gap-32 {
        margin-left: -8px;
        width: calc(100% + (2 * 8px));

        .col {
          padding: 0px 8px;
        }
      }

      &.row-gap-16,
      &.row-gap-0 {
        margin-left: 0;
        width: calc(100%);

        .col {
          padding: 0px 0px;
          width: 100%;
        }
      }
    }
  }
}