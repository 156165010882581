.customButton,
.customButton[type="submit"],
.customButton[type="reset"] {
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  border: 2px solid black;
  background: transparent;
  padding: 9.5px 45px;
  font-size: 2.2rem;
  font-weight: 400;
  user-select: none;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.03em;
  color: #252525;
  
  &:hover {
    box-shadow: inset 0px 0px 1000px rgba(242, 142, 5, 0.05);
  }

  &:active {
    box-shadow: inset 0px 0px 1000px rgba(242, 142, 5, 0.15);
  }

  &.success {
    border: 1px solid rgb(120, 230, 60);

    &:hover {
      box-shadow: inset 0px 0px 1000px rgba(120, 230, 60, 0.05);
    }

    &:active {
      box-shadow: inset 0px 0px 1000px rgba(120, 230, 60, 0.15);
    }
  }

  &.fail {
    border: 1px solid rgb(230, 66, 60);

    &:hover {
      box-shadow: inset 0px 0px 1000px rgba(230, 60, 60, 0.05);
    }

    &:active {
      box-shadow: inset 0px 0px 1000px rgba(230, 60, 60, 0.15);
    }
  }

  img {
    position: absolute;
    right: 4%;
  }
}

.customButton:disabled,
.customButton[disabled] {
  color: #aaa;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.1);
}
