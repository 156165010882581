.contact-form-section {
  background: white;
  padding-bottom: 64px;
  padding-top: 64px;
  .title {
    width: 100%;
    font-size: 4rem;
    letter-spacing: 0.05em;
    color: #3a3a3a;
    text-align: center;
    margin-top: 32px;
  }

  .subtitle {
    width: 100%;
    font-size: 2rem;
    letter-spacing: 0.05em;
    margin-top: 16px;
    color: #3a3a3a;
    text-align: center;
  }

  .contact-form {
    max-width: 1200px;
    margin: auto;
    color: #3a3a3a;
    padding-top: 48px;

    @media (max-width: 900px) {
      padding: 0px;
    }

    .customButton {
      text-align: center;
      justify-content: center;
      margin-top: 32px;
      left: 50%;
      transform: translateX(-50%);
    }

    .info-label {
      margin-top: 32px;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 12px 11px;
      width: 100%;
      box-shadow: inset 0px 0px 1000px rgba(242, 142, 5, 0.15);
      text-align: center;
      border: 1px solid #f2c98b;
    }
  }
}
