.contact-section {
  padding-top: 64px;
  padding-bottom: 64px;

  @media all and (max-width: 600px) {
    padding-top: 32px;
  }

  .container {
    height: calc(100vh - 180px);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    @media all and (max-width: 1200px) {
      height: calc(85vh - 180px);
    }

    @media all and (max-width: 700px) {
      height: auto;
    }
  }

  .whitePart {
    color: #252525;
    padding-left: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;

    @media all and (max-width: 1600px) {
      padding-left: 64px;
    }

    @media all and (max-width: 1000px) {
      padding-left: 32px;
    }

    @media all and (max-width: 600px) {
      justify-content: flex-start;
      padding-left: 0px;
      width: 100%;
    }

    .title {
      @media all and (max-width: 1600px) {
        font-size: 4rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 3.6rem;
      }
    }

    .topLinkWrapper {
      font-weight: bold;
    }

    .iconsWrapper {
      display: flex;
      padding-top: 8px;
      padding-bottom: 16px;

      a {
        margin-right: 16px;
        padding: 4px;

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
          filter: brightness(2);
          transition-duration: 0.3s;

          &:hover {
            filter: brightness(1);
          }
        }
      }
    }
  }
}
