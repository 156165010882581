.home-page {
  .swiper-pagination-bullet {
    background: rgba(0, 0, 0, 0);
    font-size: 1.6rem;
    font-weight: bold;
    color: #969696;
    opacity: 1;
    padding-left: 12px;
    padding-right: 32px;
    padding-bottom: 24px;
    transform: translateX(0px) translateY(0px);
    transition-duration: 0.2s;

    @media all and (max-width: 1400px) {
      padding-right: 24px;
    }

    &:after {
      transition-duration: 0.2s;
      content: "";
      display: block;
      border-radius: 16px;
      width: 16px;
      height: 16px;
      border: 2px solid white;

      @media all and (max-width: 1400px) {
        border-radius: 12px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .swiper-pagination-bullet-active {
    transform: translateX(-4px) translateY(-4px);

    @media all and (max-width: 1400px) {
      transform: translateX(-2px) translateY(-4px);
    }

    &:after {
      width: 24px;
      height: 24px;
      border: 12px solid white;

      @media all and (max-width: 1400px) {
        width: 18px;
        height: 18px;
        border: 9px solid white;
      }
    }
  }

  .swiper-wrapper,
  .swiper-container {
    height: calc(100vh - 100px);
    background: #252525;

    @media all and (max-width: 1400px) {
      height: calc(80vh - 180px);
    }

    @media all and (max-width: 700px) {
      height: 600px;
    }

    @media all and (max-width: 460px) {
      height: 400px;
    }

    .topLinkWrapper {
      font-size: 3.5rem;

      @media all and (max-width: 1600px) {
        font-size: 3rem;
      }

      @media all and (max-width: 1400px) {
        font-size: 2.5rem;
      }

      @media all and (max-width: 1200px) {
        font-size: 2rem;
      }
    }
  }

  .slideContantWrapper {
    display: flex;

    @media all and (max-width: 1400px) {
      position: relative;
      height: calc(80vh - 180px);
    }

    @media all and (max-width: 700px) {
      height: 600px;
    }

    @media all and (max-width: 460px) {
      height: calc(100vh - 140px);
    }

    .sliderBg {
      height: calc(100vh - 100px);

      @media all and (max-width: 1400px) {
        height: calc(80vh - 180px);
      }

      @media all and (max-width: 700px) {
        height: 600px;
      }

      @media all and (max-width: 460px) {
        height: calc(100vh - 140px);
      }

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #252525;

      @media all and (max-width: 700px) {
        width: 100%;
        position: absolute;
        filter: brightness(0.7);
      }
    }

    .sliderContent {
      text-align: center;
      justify-content: center;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 128px;
      max-width: 800px;

      @media all and (max-width: 1400px) {
        padding-right: 96px;
      }

      @media all and (max-width: 1000px) {
        padding-right: 72px;
      }

      @media all and (max-width: 700px) {
        width: 90%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-right: 48px;
      }

      @media all and (max-width: 400px) {
        width: 96%;
        padding-right: 32px;
      }

      .corner {
        height: 100px;
        width: 100%;
        border-right: 10px solid white;

        @media all and (max-width: 1600px) {
          border-right: 8px solid white;
        }

        @media all and (max-width: 1400px) {
          border-right: 6px solid white;
          height: 70px;
        }

        @media all and (max-width: 1000px) {
          border-right: 5px solid white;
          height: 50px;
        }
      }

      .topCorner {
        margin-bottom: 48px;
        border-top: 10px solid white;

        @media all and (max-width: 1600px) {
          margin-bottom: 32px;
          border-top: 8px solid white;
        }

        @media all and (max-width: 1400px) {
          border-top: 6px solid white;
        }

        @media all and (max-width: 1000px) {
          border-top: 5px solid white;
        }
      }

      .botCorner {
        margin-top: 32px;
        border-bottom: 10px solid white;

        @media all and (max-width: 1600px) {
          border-bottom: 8px solid white;
        }

        @media all and (max-width: 1400px) {
          border-bottom: 6px solid white;
        }

        @media all and (max-width: 1000px) {
          border-bottom: 5px solid white;
        }
      }

      .title {
        padding-left: 128px;
        font-size: 9.6rem;
        color: white;
        line-height: 0.9;
        font-weight: 400;
        padding-bottom: 16px;
        text-align: right;

        @media all and (max-width: 1600px) {
          font-size: 8rem;
        }

        @media all and (max-width: 1400px) {
          font-size: 6rem;
        }

        @media all and (max-width: 1000px) {
          font-size: 5rem;
        }

        @media all and (max-width: 800px) {
          font-size: 4rem;
        }
        @media all and (max-width: 460px) {
          font-size: 5.6rem;
        }
      }
    }
  }

  /////////////////// SWIPER END

  .arrowDownBtn {
    width: 30px;
    height: 48px;
    display: flex;
    text-align: center;
    margin-top: -96px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    position: relative;
    margin-bottom: 48px;

    @media all and (max-width: 1400px) {
      height: 36px;
    }

    @media all and (max-width: 1000px) {
      height: 32px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .oneSlideMobile {
    position: relative;
    margin-bottom: 70px;
    .arrowDownBtn {
      position: absolute;
      bottom: calc(-70px / 2);
      margin-bottom: 0px;
      margin-top: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
    }
  }
}
