a,
.topLinkWrapper {
  text-decoration: none;
  color: #252525;

  &:hover {
    color: #f2c98b !important;
  }
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 0.9rem !important;
  border-radius: 4px !important;
  padding: 5px 20px !important;
  min-height: 30px !important;
  color: white !important;
  font-weight: 600 !important;
}

.toasts-container {
  top: 80px !important;
  right: 25px !important;

  .toast {
    font-size: 1.5rem !important;
  }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.overlay {
  transition-delay: 0s;
  transition-duration: 0.2s;
  filter: blur(0px);
  opacity: 1;
}

.overlay.isActive {
  transition-delay: 0s;
  transition-duration: 0.4s;
  // backdrop-filter: brightness(0.75);
  // background: red;
  // width: 100%;
  // height: 100%;
  // display: block;
  // position: absolute;
  // background: red;
  // background: rgba(0, 0, 0, 0.3);
  // filter: blur(40px);
  // backdrop-filter: brightness(0.75);
}

.showOnMobile {
  @media all and (min-width: 701px) {
    display: none !important;
  }
}

.hideOnMobile {
  @media all and (max-width: 700px) {
    display: none !important;
  }
}
