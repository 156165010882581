.container {
  width: 100%;
  padding: 0px 128px;
  display: flex;
  flex-direction: column;
  max-width: 2560px;
  margin: auto;

  @media all and (max-width: 1200px) {
    padding: 0px 32px;
  }

  @media all and (max-width: 600px) {
    padding: 0px 16px;
  }

  &.isRaw {
    padding: 0px;
  }
}