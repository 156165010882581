.navbarBarWrapper {
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.navbarBar {
  width: 100%;
  height: 100px;
  background: white;
  z-index: 9988;
  display: flex;

  @media all and (max-width: 1200px) {
    height: 80px;

    .hideOnMobile {
      display: none;
    }

    .col-6 {
      width: calc((100% / 12) * 6) !important;
    }

    .col-3 {
      width: calc((100% / 12) * 6) !important;
    }
  }

  @media all and (max-width: 700px) {
    height: 70px;
  }
}

.navbarLogo {
  height: 100%;
  align-items: center;
  z-index: 999;
  display: flex;
  justify-content: center;
  // position: relative;
  @media all and (max-width: 1200px) {
    justify-content: left;
  }

  img {
    padding: 8px;
    cursor: pointer;
    height: 100px;
  }

  @media all and (max-width: 1200px) {
    height: 80px;

    img {
      height: 80px;
    }
  }

  @media all and (max-width: 700px) {
    height: 70px;

    img {
      height: 70px;
    }
  }
}

.menuBtn {
  right: 0px;
  cursor: pointer;
  z-index: 904;
  height: 100px;
  width: 100px;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  transform: translateX(24px);
  user-select: none;
  margin-left: auto;

  @media all and (max-width: 1200px) {
    height: 80px;
    width: 80px;
    padding: 24px;
  }

  @media all and (max-width: 700px) {
    height: 70px;
    width: 70px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.menuBtn.navbarbarActive {
  top: 24px;
}

.mobileSideMenuOverlay {
  width: 150vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  right: 0px;
  transform: translateX(150vw);
  z-index: 900;
  transition-duration: 0.3s;

  &.isActive {
    background: rgba(99, 99, 99, 0.3);
    transition-duration: 0.3s;

    // transform: translateX(45vw);
  }
}

.mobileSideMenu {
  z-index: 903;
  display: flex;
  flex-direction: column;
  background: rgba(49, 49, 49, 0.9);
  position: fixed;
  right: 0px;
  bottom: 0px;
  color: white; // rgba(49,49,49,1);
  text-align: right;
  transition-duration: 0.3s;
  // background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, #252525 100%);
  width: 100vw;

  @media all and (max-width: 400px) {
    text-align: center;
    justify-content: center;
  }

  .mobileSideMenuInsetWrapper {
    padding: 128px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    transition-duration: 0.3s;

    @media all and (max-width: 700px) {
      padding: 32px;
      padding-top: 12vh;
    }

    &.isScrolled {
      height: calc(100vh - 100px);

      @media all and (max-width: 1200px) {
        height: calc(100vh - 80px);
      }

      @media all and (max-width: 700px) {
        height: calc(100vh - 70px);
      }
    }
  }

  a {
    display: table;
    margin-left: auto;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 400px;
    opacity: 0;
    color: white;

    @media all and (max-width: 700px) {
      min-width: auto;
    }

    @media all and (max-width: 400px) {
      margin-right: auto;
    }
  }

  .linkLarge {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 3.6rem;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    padding-bottom: 12px;
    padding-top: 0px;
    transition-duration: 0.3s;
    opacity: 0;

    @media all and (max-width: 1200px) {
      font-size: 2.8rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  transform: translateX(100%);
  opacity: 0;
  transition-duration: 0.3s;

  &.isActive {
    opacity: 1;
    transform: translateX(0);
    transition-duration: 0.3s;

    a {
      opacity: 1;
    }

    .linkLarge {
      opacity: 1;
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }

  .iconsWrapper {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    margin-top: 64px;

    @media all and (max-width: 700px) {
      margin-top: 24vh;
      justify-content: center;
    }

    a {
      margin-left: 32px;
      min-width: 0px;

      img {
        cursor: pointer;
        width: 28px;
        height: 28px;
        filter: brightness(2);
        transition-duration: 0.3s;

        &:hover {
          filter: brightness(1);
        }
      }
    }

    @media all and (max-width: 700px) {
      a {
        margin-left: 16px;
        margin-right: 16px;

        img {
          width: 26px;
          height: 26px;
          filter: brightness(100);
        }
      }
    }
  }
}
