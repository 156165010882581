.map-section {
  width: 100%;
  z-index: 0;

  .map {
    width: 100%;
    height: 640px;
    z-index: -5;
    filter: grayscale(1) invert(0.9);

    @media all and (max-width: 700px) {
      height: 400px;
    }
  }
}