.standard-block {
  font-size: 1.6rem;
  &.center {
    margin: auto;
  }
  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.05em;
    margin-bottom: 0px;
    color: white;
  }

  .title {
    font-weight: 400;
    font-size: 4.8rem;
    letter-spacing: 0.05em;
    color: white;
  }

  .subtitle {
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
    color: white;

    strong {
      font-weight: 700;
    }
  }

  &.white {
    p,
    .title,
    .subtitle {
      color: white;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  .red {
    font-weight: 300;
    color: #e62424;
  }

  .topLinkWrapper {
    padding-top: 16px;
    font-size: 2.4rem;
    color: white;
    letter-spacing: 0.05em;
    font-weight: 400;
    cursor: pointer;
    display: inline-block;

    @media all and (max-width: 1600px) {
      font-size: 2.2rem;
    }

    @media all and (max-width: 1400px) {
      font-size: 2rem;
    }

    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
    }

    @media all and (max-width: 1000px) {
      font-size: 1.6rem;
    }

    &:after {
      content: "";
      width: 100%;
      margin-top: 5px;
      height: 1px;
      display: block;
      background: white;
    }

    &.dark {
      color: white;

      &:after {
        background: white;
      }
    }

    &:hover {
      color: #f2c98b;

      &:after {
        background: #f2c98b;
      }
    }

    &:active {
      color: #f2c98b;

      &:after {
        background: #f2c98b;
      }
    }
  }

  ul {
    font-weight: 300;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    list-style-type: "\2022"; //none;

    li {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  strong {
    font-weight: 500;
  }
}
