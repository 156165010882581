.oferta-page {
  .collapsible-block-standard {
    margin-bottom: 0px;
  }

  .cta-block {
    margin-top: 15vh;

    @media all and (max-width: 700px) {
      margin-top: 64px;
      margin-bottom: 32px;
    }
  }
}